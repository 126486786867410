import { Link } from 'gatsby'
import React from 'react'
import type { Company } from '../../types/unicornvalley'
import './CompanyProfile.scss'

const defaultSize = `big`

interface Props {
  company: Company,
  size?: typeof defaultSize | `normal`,
  vertical?: boolean,
}

const CompanyProfile: React.FC<Props> = ({
  size = defaultSize,
  company: {
    id,
    name,
    tagline,
    picture,
    isRemoved,
  },
  vertical = false,
}) => (
    <Link
      className={[
        `company-profile`,
        size,
        ...(id ? [] : [`no-hover`]),
        ...(isRemoved ? [`hidden`] : []),
        ...(vertical ? [`vertical`] : []),
      ].join(` `)}
      to={id ? `/companies/${id}` : `#`}
    >
      <img src={picture} alt={name} />
      <div className="content">
        <h3>{name}</h3>
        <p>{tagline}</p>
      </div>
    </Link>
  )

export default CompanyProfile